import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevRaidFog: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Fog of War</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_fog.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Fog of War</h1>
          <h2>Everything you need to know about the Fog of War Raid!</h2>
          <p>
            Last updated: <strong>15/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <p>
        Also a returning boss from chapter 7 and various events afterwards, the
        Fog of War takes flight to lay down the heat in Mane’s Bulletin. To get
        this one to crash from between the clouds you’ll have to bring follow-up
        attacks / extra actions and crowd control; think Anjo Nala, 37 and maybe
        even Druvis III.
      </p>
      <SectionHeader title="Stage Hazards" />
      <ul>
        <li>[All] Gain 5 stacks of [Schemed] and [Fleeting - Schemed].</li>
        <ul>
          <li>
            [Schemed]: After taking an Extra Action, DMG Dealt +5% (stackable up
            to 50, undispellable).
          </li>
          <li>
            [Fleeting - Schemed]: At the end of the round, lose 1 stack of
            [Schemed] (stackable, undispellable).
          </li>
        </ul>
        <li>
          [Ally] After casting a Counter or Channel Incantation, gain +1 Moxie.
        </li>
        <li>
          [Ally] Mental DMG units gain +20% ATK, Reality DEF, Mental DEF and Max
          HP.
        </li>
      </ul>
      <SectionHeader title="Boss Mechanics [Endless Mode]" />
      <h5>Fog of War (Boss)</h5>
      <ul>
        <li>
          <strong>Charged for Conflict</strong> - After allies take action, gain
          1 stack of [Charge Barrage].
        </li>
        <ul>
          <li>
            [Charge Barrage]: At the start of the turn, for every 2 stacks, cast
            [Burst].
          </li>
        </ul>
        <li>
          <strong>Energy Overloading</strong> - At the end of the round, if the
          caster has more than 10 stacks of [Charge Barrage], remove all stacks
          and gain 4 stacks of [Crush].
        </li>
        <ul>
          <li>[Crush]: DMG Reduction -100% (-1 stack upon trigger).</li>
        </ul>
        <li>
          <strong>Heavy Armor</strong> - Upon taking an action, gain 1 stack of
          [Tenacity II]. When taking Reality DMG, inflict 1 random effect from
          [Collection of Debuffs] on the attacker for 2 rounds.
        </li>
        <ul>
          <li>
            [Tenacity II]: DMG Reduction +50% (-1 stack upon trigger,
            undispellable).
          </li>
        </ul>
        <li>
          <strong>[Ultimate] Card</strong>
        </li>
        <li>
          <strong>[Attack] Card</strong> - Mass attack. Deals 250% Reality DMG
          to 2 enemies. This attack deals +40% Reality DMG for each [Neg Status]
          on struck targets.
        </li>
        <li>
          <strong>[Attack] Card</strong> - Mass attack. Deals 50% Reality DMG.
          If the caster has less than 3 [Neg Status], this inflicts an
          additional 50% Reality DMG.
        </li>
      </ul>
      <h5>Fanatical Congregation (Male Follower)</h5>
      <ul>
        <li>
          <strong>Mental Barrier</strong> - At the start of the round, if the
          caster is not in a [Control] status, it gains [Mental Barrier]. Upon
          gaining a [Control] status, dispel own [Mental Barrier] and remove 2
          stacks of [Tenacity II] from the ally with the highest ATK.
        </li>
        <ul>
          <li>
            [Mental Barrier]: Forcefield. DMG Reduction +50% when attacked by
            Mental DMG.
          </li>
        </ul>
        <li>
          <strong>Flesh and Blood</strong> - Upon dying, heal 100% Lost HP and
          gain [Daze] for 1 round. If the caster is in a [Control] status,
          remove all Moxie.
        </li>
        <li>
          <strong>Ultimate Card</strong> - Mass attack. Deal 250% Mental DMG. If
          the caster has less than 3 [Neg Status], inflicts 2 effects randomly
          from [Collection of Debuffs] for 2 rounds.
        </li>
        <li>
          <strong>[Attack] Card</strong> - 1-target attack. Deals 200% Mental
          DMG. After casting, the target and the caster gain 1 effect from
          [Collection of Debuffs] for 2 rounds.
        </li>
      </ul>
      <h5>Devoted Congregation (Female Follower) </h5>
      <ul>
        <li>
          <strong>Reality Barrier</strong> - At the start of the round, if the
          caster is not in a [Control] status, it gains [Reality Barrier]. Upon
          gaining a [Control] status, dispel own [Reality Barrier] and remove 2
          stacks of [Tenacity II] from the ally with the highest ATK.
        </li>
        <ul>
          <li>
            [Reality Barrier]: Forcefield. DMG Reduction +50% when attacked by
            Reality DMG.
          </li>
        </ul>
        <li>
          <strong>Flesh and Blood</strong> - Upon dying, heal 100% Lost HP and
          gain [Daze] for 1 round. If the caster is in a [Control] status,
          remove all Moxie.
        </li>
        <li>
          <strong>Ultimate Card</strong> - 1-target attack. Deal 400% Mental
          DMG. If the caster has less than 3 [Neg Status], inflicts 2 effects
          randomly from [Collection of Debuffs] for 2 rounds.
        </li>
        <li>
          <strong>[Attack] Card</strong> - Mass attack. Deals 100% Mental DMG to
          2 enemies. After casting, the target and the caster gain 1 effect from
          [Collection of Debuffs] for 2 rounds.
        </li>
      </ul>
      <h5>Observer (Orb Holder)</h5>
      <ul>
        <li>
          <strong>Spread Suffering</strong> - At the start of the round, if the
          caster is not in a [Control] status, grant all allies +1 Moxie and
          gain a random effect from [Collection of Debuffs] for 2 rounds. Upon
          gaining a [Control] status, all allies gain 3 random effects from
          [Collection of Debuffs] for 2 rounds.
        </li>
        <li>
          <strong>Flesh and Blood</strong> - Upon dying, heal 100% Lost HP and
          gain [Daze] for 1 round. If the caster is in a [Control] status,
          remove all Moxie.
        </li>
        <li>
          <strong>Ultimate Card</strong> - Mass attack. Deals 200% Mental DMG.
          Inflicts 2 random effects from [Collection of Debuffs] for 2 rounds.
        </li>
        <li>
          <strong>Debuff Card</strong> - 1-target attack. Deals 150% Mental DMG.
          After casting, the target and the caster gain 1 effect from
          [Collection of Debuffs] for 2 rounds.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        There’s a few things here. Beyond Extra Actions, you’ll want a lot of
        Crowd Control. This can be done in a few ways, one of the more common
        ways is a modified Upgrade team with characters like An-An Lee and
        Druvis III, the other is using Anjo Nala in a [Contract] with a
        character that can trigger her often (i.e. a Star or Mineral unit).
      </p>
      <div className="team-box">
        <h5 className="beast">CAPTIVATING DORITO</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="lucy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="marcus"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="anjo-nala"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="mercuria"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kakania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="isolde"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="vila"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>Contracted: 37, Marcus, Lucy.</p>
          <p>
            So long as Anjo Nala is in the [Hymn] channel and 37 is casting as
            much as possible, they’ll feed into each other’s mechanics. 37 will
            cause Nala to cast “Angel’s Temptation” often (and thus stack
            [Titillated] quickly for a [Captivated] hard CC cycle while Nala’s
            extra actions will allow 37 to quickly charge Eureka.
          </p>
          <p>
            With a defensively AP-positive anchor in Kakania and Vila will allow
            the rest to cast while the team is kept relatively protected
            passively through healing and damage redirection.
          </p>
          <p>
            Lucy isn’t as optimal here as the followers don’t die and Mental DMG
            units are king. However, Mercuria can work in case Anjo Nala or
            Kakania are unavailable, while realistically any healer can work so
            long as 37 and Anjo Nala can cast.
          </p>
          <p>
            Marcus is also an option in the Carry slot due to her consistent
            mass DMG and ability to let Anjo Nala cast lots of Extra Actions.
            Nala is pretty key to beating this boss though, primarily due to
            being the only character with consistent mass CC.
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevRaidFog;

export const Head: React.FC = () => (
  <Seo
    title="Fog of War | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Fog of War Raid!"
    game="reverse"
  />
);
